import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface SignUpFormPaginationProps {
  step: number;
  stepsAmount: number;
}
interface SignUpFormPaginationItemProps {
  step: number;
  isActive: boolean;
}

function getStepsAmountList(amount: number) {
  const arr = [];
  for (let i = 1; i <= amount; i++) {
    arr.push(i);
  }
  return arr;
}

const SignUpFormPaginationItem = (props: SignUpFormPaginationItemProps) => {
  const { t } = useTranslation();

  const { step, isActive } = props;
  const stepBoxCn = clsx(
    'flex justify-center items-center w-10 h-10 mr-3 rounded-sm transition-colors font-bold',
    isActive ? 'bg-rich-blue text-white' : 'bg-solitude text-rich-blue',
  );

  return (
    <li className="flex items-center">
      <span className={stepBoxCn}>{step}</span>
      <span className="text-xs text-rich-blue font-bold">{t('step')}</span>
    </li>
  );
};

export const SignUpFormPagination: React.FC<SignUpFormPaginationProps> = (props) => {
  const { step, stepsAmount } = props;
  const stepsListData = getStepsAmountList(stepsAmount);

  const stepsList = useMemo(
    () =>
      stepsListData.map((_step) => ({
        step: _step,
        isActive: _step === step,
      })),
    [step],
  );

  return (
    <ul className="flex justify-center space-x-8">
      {stepsList.map((item) => (
        <SignUpFormPaginationItem key={item.step} {...item} />
      ))}
    </ul>
  );
};
