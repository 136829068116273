import { emptySplitApi } from '../emptySplitApi';
import { ApplicationCategory, ExchangeRate, GeoLocality, GeoRegion } from './types';

const extendedApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    dictionaryRegions: build.query<GeoRegion[], void>({
      query: () => ({ url: '/dictionaries/regions' }),
      providesTags: ['Regions'],
    }),
    dictionaryCategories: build.query<ApplicationCategory[], void>({
      query: () => ({ url: '/dictionaries/categories' }),
      providesTags: ['Categories'],
    }),
    dictionarySocialTags: build.query<ApplicationCategory[], void>({
      query: () => ({ url: '/dictionaries/users/groups' }),
      providesTags: ['SocialTags'],
    }),
    dictionaryCurrenciesExchangeRates: build.query<ExchangeRate[], void>({
      query: () => ({ url: '/dictionaries/currencies/exchange' }),
      providesTags: ['ExchangeRates'],
    }),
    dictionaryLocalities: build.query<GeoLocality[], { needle: string; regionId?: number | null }>({
      query: ({ needle, regionId }) => {
        const params: { needle: string; regionId?: number | null } = { needle };
        if (regionId) {
          params.regionId = regionId;
        }

        return { url: '/dictionaries/localities/search', params };
      },
      providesTags: ['Localities'],
    }),
  }),
  overrideExisting: false,
});

extendedApi.enhanceEndpoints({
  addTagTypes: ['Regions'],
});

export const {
  useDictionaryRegionsQuery,
  useDictionarySocialTagsQuery,
  useDictionaryLocalitiesQuery,
  useDictionaryCategoriesQuery,
  useDictionaryCurrenciesExchangeRatesQuery,
} = extendedApi;
