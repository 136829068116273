import { getRegCodeValidation } from '@shared/utils';
import { FIELD_PASSWORD } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import * as yup from 'yup';

export const FIELD_LEGAL_NAME: string = 'legalName';
export const FIELD_CONTACT_NAME: string = 'contactName';
export const FIELD_REG_CODE: string = 'regCode';

export const FIELD_EMAIL: string = 'email';

export const FIELD_REGION: string = 'region';
export const FIELD_DISTRICT: string = 'district';
export const FIELD_LOCALITY: string = 'locality';
export const FIELD_STREET: string = 'street';

export const FIELD_APPROVE: string = 'approve';

export const FIELD_PHONE: string = 'phone';

export const FIELD_AVATAR: string = 'avatar';

export function getValidationSchemaInNeedLegalPerson(language: string) {
  const validationSchemaInNeedLegalPerson = yup.lazy((_, options) => {
    const phoneValidationResult = options.context?.getPhoneInnerValidationResult?.();
    const regCodeBaseValidation = getRegCodeValidation(language);

    return yup.object().shape({
      [FIELD_LEGAL_NAME]: yup.string().trim().required('errors.required'),
      [FIELD_CONTACT_NAME]: yup.string().trim().required('errors.required'),
      [FIELD_REG_CODE]: regCodeBaseValidation.required('errors.required'),

      [FIELD_REGION]: yup.string().trim().required('errors.required'),
      [FIELD_LOCALITY]: yup.string().trim().required('errors.required'),
      [FIELD_STREET]: yup.string().trim().required('errors.required'),
      [FIELD_DISTRICT]: yup.string().trim().required('errors.required'),
      [FIELD_EMAIL]: yup.string().trim().email('errors.invalid-email'),

      [FIELD_APPROVE]: yup.bool().oneOf([true], 'errors.checked'),
      [FIELD_PASSWORD]: yup
        .string()
        .trim()
        .required('errors.required')
        .min(6, 'errors.too-short')
        .max(20, 'errors.too-long'),
      [FIELD_PHONE]: yup
        .string()
        .trim()
        .required('errors.required')
        .matches(/^\+[0-9]+/i, 'errors.invalid-phone-number')
        .test({
          test: () => phoneValidationResult?.isValidPhone || false,
          message: phoneValidationResult?.phoneErrorMessage || '',
        }),
    });
  });

  return validationSchemaInNeedLegalPerson;
}
