import { yupResolver } from '@hookform/resolvers/yup';
import { useLogIn } from '@hooks/useLogIn';
import { useNavigate } from '@hooks/useNavigate';
import { createPrivateInNeedUser } from '@services/users';
import { ApplicantType, Routes } from '@shared/enums';
import { useCabinetNavigate } from '@shared/hooks/useCabinetNavigate';
import { SignUpFormControls } from '@shared/signUp/SignUpFormControls';
import { SignUpFormPagination } from '@shared/signUp/SignUpFormPagination';
import { CheckboxField } from '@shared/ui/CheckboxField';
import { ApplicantCategoryDropdown } from '@shared/ui/dropdowns/ApplicantCategoryDropdown';
import { LocalityDropdown } from '@shared/ui/dropdowns/LocalityDropdown';
import { RegionDropdown } from '@shared/ui/dropdowns/RegionDropdown';
import { ErrorList } from '@shared/ui/ErrorList';
import { PhoneInput, usePhoneInnerValidation } from '@shared/ui/PhoneInput/PhoneInput';
import { TextareaField } from '@shared/ui/TextareaField';
import { TextField } from '@shared/ui/TextField';
import { checkIsFieldsValid } from '@shared/utils';
import { isApiError, translateApiError } from '@shared/utils/errors';
import { useSignUpInNeedMutation } from '@store/api/user.api';
import { SignUpApproveProcessLabel } from '@widgets/signUp/SignUpApproveProcessLabel';
import { FIELD_PASSWORD } from '@widgets/signUp/signUpVolunteer/signUpVolunteerPrivatePerson/constants';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSignUpPersonContext } from '../../SignUpPersonContext';
import {
  FIELD_AVATAR,
  FIELD_ABOUT,
  FIELD_APPROVE,
  FIELD_DISTRICT,
  FIELD_EMAIL,
  FIELD_LOCALITY,
  FIELD_NAME,
  FIELD_PHONE,
  FIELD_REGION,
  FIELD_SOCIAL_TAG,
  FIELD_STREET,
  validationSchemaInNeedPrivatePerson,
} from './constants';
import { AvatarFormInput } from '@shared/ui/avatar/AvatarFormInput';

const initialFormValues = {
  [FIELD_AVATAR]: null,
  [FIELD_NAME]: '',
  [FIELD_PHONE]: '',
  [FIELD_REGION]: '',
  [FIELD_LOCALITY]: '',
  [FIELD_STREET]: '',
  [FIELD_ABOUT]: '',
  [FIELD_DISTRICT]: '',
  [FIELD_EMAIL]: '',
  [FIELD_PASSWORD]: '',
  [FIELD_APPROVE]: false,
  [FIELD_SOCIAL_TAG]: [],
};

const stepsAmount = 2;
const firstStepFieldsToValidate = [FIELD_NAME, FIELD_PHONE, FIELD_REGION, FIELD_LOCALITY, FIELD_STREET];

export const SignUpInNeedPrivatePerson = () => {
  const navigate = useNavigate();
  const { applicantType, step, setStep } = useSignUpPersonContext();

  const [signUpInNeedUser] = useSignUpInNeedMutation();

  const { t } = useTranslation();
  const [apiErrors, setApiErrors] = useState<string[]>([]);

  const { getPhoneInnerValidationResult, setPhoneInnerValidationResult } = usePhoneInnerValidation();
  const cabinetNavigate = useCabinetNavigate();
  const logIn = useLogIn();

  const methods = useForm({
    resolver: yupResolver(validationSchemaInNeedPrivatePerson),
    defaultValues: initialFormValues,
    context: {
      getPhoneInnerValidationResult,
    },
  });

  const {
    control,
    handleSubmit,
    trigger,
    getFieldState,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isSubmitting, isValidating, errors },
  } = methods;

  useEffect(() => {
    setValue(FIELD_LOCALITY, '');
  }, [watch(FIELD_REGION)]);

  const isLastStep = step === stepsAmount;

  const handleNextButtonClick = async () => {
    await trigger(firstStepFieldsToValidate);
    const isFirstStepFieldsValid = checkIsFieldsValid({ fieldList: firstStepFieldsToValidate, getFieldState });

    if (isFirstStepFieldsValid) {
      setStep(step + 1);
      return;
    }
  };

  const handleBackButtonClick = () => {
    if (step !== 1) {
      setStep(step - 1);

      return;
    }

    navigate(Routes.AuthSignUp);
  };

  const onSubmit = async (data: any) => {
    const { name, street, locality, region, about, district, phone, avatar, groups, email, password } = data;
    const user = createPrivateInNeedUser({
      contactName: name,
      phoneNumber: phone,
      address: { country: 'ua', regionId: region, localityId: locality, streetName: street, district },
      applicantType: ApplicantType.HumanBeing,
      description: about,
      groups,
      password,
      email,
      avatar,
    });

    try {
      const { token, payload } = await signUpInNeedUser(user).unwrap();
      logIn(payload, token);
      cabinetNavigate(payload.type);
      reset();
    } catch (err) {
      if (isApiError(err)) {
        setApiErrors(translateApiError(err, t));
      }

      // TODO: handle bad errors
    }
  };

  if (applicantType !== ApplicantType.HumanBeing) return null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-[528px] mx-auto">
        <div className="sign-up-form-card">
          <SignUpFormPagination step={step} stepsAmount={2} />
          <div hidden={step !== 1} className="mt-10">
            <AvatarFormInput />

            <TextField id={FIELD_NAME} label="name-surname" className="mt-10" autoFocus />

            <Controller
              name={FIELD_PHONE}
              control={control}
              render={({ field: { value, onChange } }) => {
                return (
                  <PhoneInput
                    value={value}
                    onChange={onChange}
                    className="mt-10"
                    errorText={errors[FIELD_PHONE]?.message}
                    setPhoneInnerValidationResult={setPhoneInnerValidationResult}
                  />
                );
              }}
            />

            <TextField id={FIELD_EMAIL} type="email" label="email" className="mt-10" />

            <Controller
              name={FIELD_REGION}
              control={control}
              render={({ field: { value, onChange } }) => (
                <RegionDropdown
                  value={value as string}
                  className="mt-10"
                  errorText={errors[FIELD_REGION]?.message}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name={FIELD_LOCALITY}
              control={control}
              render={({ field: { value, onChange } }) => (
                <LocalityDropdown
                  regionId={getValues(FIELD_REGION)}
                  value={value as string}
                  onChange={onChange}
                  className="mt-10"
                  errorText={errors[FIELD_LOCALITY]?.message}
                  disabled={!getValues(FIELD_REGION)}
                />
              )}
            />

            <TextField id={FIELD_DISTRICT} label="area" className="mt-10" />

            <TextField id={FIELD_STREET} label="street" className="mt-10" />
          </div>
          <div hidden={step === 1} className="mt-10">
            <Controller
              name={FIELD_SOCIAL_TAG}
              control={control}
              render={({ field: { value, onChange } }) => {
                return <ApplicantCategoryDropdown value={value} onChange={onChange} />;
              }}
            />
            <TextField id={FIELD_PASSWORD} type="password" label="password" className="mt-10" />
            <TextareaField id={FIELD_ABOUT} label="about-me" placeholder="about-me-extended" className="mt-10" />
          </div>

          <ErrorList errors={apiErrors} className="mt-6" />
        </div>
        <div hidden={step === 1}>
          <CheckboxField id={FIELD_APPROVE} label={<SignUpApproveProcessLabel />} className="mt-6 mx-6 md:mx-14" />
        </div>

        <SignUpFormControls
          multiStep
          isLastStep={isLastStep}
          nextStepDisabled={isValidating}
          submitDisabled={isSubmitting || isValidating}
          onNextButtonClick={handleNextButtonClick}
          onBackButtonClick={handleBackButtonClick}
        />
      </form>
    </FormProvider>
  );
};
