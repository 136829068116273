import React from 'react';

import { UserType } from '@shared/enums';
import { OauthProviders } from '@widgets/auth/OauthProviders';
import { SignUpInNeed } from '@widgets/signUp/signUpInNeed/SignUpInNeed';
import { SignUpPersonProvider } from '@widgets/signUp/SignUpPersonContext';
import { SignUpRegisterTitle } from '@widgets/signUp/SignUpRegisterTitle';

const SignUpCustomerPage = () => {
  return (
    <SignUpPersonProvider>
      <div className="px-4 pt-6 pb-12 lg:pt-12">
        <SignUpRegisterTitle />
        <OauthProviders type={UserType.InNeed} />
        <SignUpInNeed />
      </div>
    </SignUpPersonProvider>
  );
};

export default SignUpCustomerPage;
