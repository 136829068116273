import React from 'react';

import { SignUpPersonTabs } from '../SignUpPersonTabs';
import { SignUpInNeedPrivatePerson } from './signUpInNeedPrivatePerson/SignUpInNeedPrivatePerson';
import { SignUpInNeedLegalPerson } from './signUpInNeeLegalPerson/SignUpInNeedLegalPerson';

export const SignUpInNeed = () => {
  return (
    <>
      <SignUpPersonTabs />
      <SignUpInNeedPrivatePerson />
      <SignUpInNeedLegalPerson />
    </>
  );
};
